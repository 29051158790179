import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/alumni-workshops-website/alumni-workshops-website/src/components/workshop-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`How to Hack the Interview Process`}</h1>
    <h2>{`2022-03-11 12:00pm (Noon)`}</h2>
    <p><em parentName="p">{`Duration: 1 hour`}</em></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220311T200000Z%2F20220311T210000Z&details=In%20this%20session%2C%20Morgan%20Lopez%2C%20a%20seasoned%20hiring%20manager%20at%20a%20variety%20of%20companies%2C%20will%20cover%20tips%20and%20strategies%20to%20increase%20your%20odds%20of%20finding%20a%20successful%20job%20in%20tech.&location=http%3A%2F%2Flovelace-labs.adadev.org&text=How%20to%20Hack%20the%20Interview%20Process%20with%20Morgan%20Lopez"
        }}>{`Add To Google Calendar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=In%20this%20session%2C%20Morgan%20Lopez%2C%20a%20seasoned%20hiring%20manager%20at%20a%20variety%20of%20companies%2C%20will%20cover%20tips%20and%20strategies%20to%20increase%20your%20odds%20of%20finding%20a%20successful%20job%20in%20tech.&enddt=2022-03-11T21%3A00%3A00%2B00%3A00&location=http%3A%2F%2Flovelace-labs.adadev.org&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-03-11T20%3A00%3A00%2B00%3A00&subject=How%20to%20Hack%20the%20Interview%20Process%20with%20Morgan%20Lopez"
        }}>{`Add to Microsoft Calendar`}</a></li>
    </ul>
    <p>{`How can you stand out from other applicants, even without as much experience?`}</p>
    <p>{`You want a great job but it is taking more time, effort, and headaches than you'd like. This presentation gives you the upper hand in the interview process and reduces the time needed to make your job a reality. Learn what most people forget during the interview process and walk away with 10 techniques that allow yourself to shine.`}</p>
    <h2>{`10 Techniques to increase your odds of landing your first job.`}</h2>
    <p>{`A seasoned hiring manager at a variety of companies, Morgan Lopes cover tips and strategies to increase a your odds of finding a successful job in tech, with topics such as:`}</p>
    <ul>
      <li parentName="ul">{`Stand out from other candidates.`}</li>
      <li parentName="ul">{`Make a great impression.`}</li>
      <li parentName="ul">{`Find your habits and rituals.`}</li>
      <li parentName="ul">{`Choose the right type of company`}</li>
      <li parentName="ul">{`Offer negotiation`}</li>
    </ul>
    <p>{`Come ready to learn, interact, and improve your chances during the interview process.`}</p>
    <h2>{`Morgan Lopes`}</h2>
    <p>{`Morgan founded Polar Notion, a software agency in Atlanta, and served as CEO for ten years. He currently serves as the CTO for New Story Charity (YC 15), named Fast Company's World Most Innovative Company four consecutive years. He lives at the intersection of entrepreneurship, technology, and impact.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b2667e3d21b91472edb972211cdd277c/8ccd0/mogan-lopes.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGgABAAIDAQAAAAAAAAAAAAAAAAQFAQIDBv/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABuY3asKvGCPkdSyQK/8QAGhAAAwADAQAAAAAAAAAAAAAAAQIDABESI//aAAgBAQABBQKjcpJ27yrALG3rjMWO9laOB//EABYRAQEBAAAAAAAAAAAAAAAAABEBIP/aAAgBAwEBPwGBj//EABYRAAMAAAAAAAAAAAAAAAAAAAEgMf/aAAgBAgEBPwE1P//EABwQAAICAwEBAAAAAAAAAAAAAAERABICECFBcf/aAAgBAQAGPwJiI530mLeCG5X3TJ7OzmRn/8QAHBABAAIDAAMAAAAAAAAAAAAAAQARITFREGGB/9oACAEBAAE/IWSi9FzEcPrXjcQ3O2G0R+IIlmSJHPUterTspRDgz//aAAwDAQACAAMAAAAQt8i//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQEg/9oACAEDAQE/EIRx/8QAFhEBAQEAAAAAAAAAAAAAAAAAAREg/9oACAECAQE/EExMf//EABwQAQEAAwADAQAAAAAAAAAAAAERACExEEFx8f/aAAgBAQABPxCHhogoKyvzCgB3gb469eGA6EBLcxgOPBQ8D9wEgmxGjjIk7TvNoyQVXJ+96Az/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Morgan Lopes thumbnail",
            "title": "Morgan Lopes thumbnail",
            "src": "/static/b2667e3d21b91472edb972211cdd277c/e5166/mogan-lopes.jpg",
            "srcSet": ["/static/b2667e3d21b91472edb972211cdd277c/f93b5/mogan-lopes.jpg 300w", "/static/b2667e3d21b91472edb972211cdd277c/b4294/mogan-lopes.jpg 600w", "/static/b2667e3d21b91472edb972211cdd277c/e5166/mogan-lopes.jpg 1200w", "/static/b2667e3d21b91472edb972211cdd277c/8ccd0/mogan-lopes.jpg 1792w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      